/**
 * React.
 */
import React from "react"

/**
 * Async Storage.
 * https://react-native-async-storage.github.io/async-storage/docs/usage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Gatsby.
 */
import { graphql } from "gatsby"

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

const WpMember = ({ data }) => {

  /* If the user is not logged in, automatically redirect to login page */
  (async () => {
    try {
      /* Get users logged in status */
      const jsonValue = await AsyncStorage.getItem('siteVals')
      const result = ( jsonValue != null ) ? JSON.parse( jsonValue ) : '' 
      /* If user is not logged in */
      if ( ! result.userLoggedIn ) {
        /* Display notice to user */
        document.getElementById( 'content' ).innerHTML = '<div class="redirect-login">Redirecting to login...</div>'
        /* Redirect to login */
        document.location = "/login"
      }
    } catch (e) {
      /* Display error(s) */
      console.log( e )
    }
   })()

  /* Get wpMember data variables */
  const {
    wpMember: { title, content, id },
  } = data     

  return (
    <Layout className={`post-${id}`}>

      {/* SEO details */}
      <Seo title={title}/> 

      {/* Page title */}
      <h1>{title}</h1>

      {/* Page content */}
      <div dangerouslySetInnerHTML={{ __html: content }} />

    </Layout>
  )
}

/* Get wpMember data */
export const query = graphql`
  query($id: String) {
    wpMember(id: { eq: $id }) {
      id
      title
      content
    }
  }
`

export default WpMember